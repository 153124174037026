import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'YouthFestivalAngular';

  constructor(private router: Router) {
    this.redirectToAdminIfNecessary();
  }


  redirectToAdminIfNecessary() {

    // Get the current hostname (subdomain)
    const hostname = window.location.hostname;
    // throw new Error("Sentry Test Error");
    // Check if the subdomain is "admin.mahanyf2024.com"
    if (hostname === 'admin.mahanyf2024.com') {
      // Redirect to the admin page
      this.router.navigate(['/super-admin']); // Replace '/admin' with the actual route you want to redirect to
    }
  }
}
