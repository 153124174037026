import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  constructor(private authService: AuthService, private fb: FormBuilder, private toastr: ToastrService, private router: Router) {
    this.loginForm = this.fb.group({
      email: ['',[Validators.required, Validators.email]],
      password: ['', [Validators.required]]
    })
   }

  ngOnInit(): void {
  }

  login() {

    if(this.loginForm.valid) {
      this.authService.loginWithEmail(this.loginForm.value.email, this.loginForm.value.password).then((res:any) => {
        console.log(res);
        // const token =  res.user.getIdToken();
        // const tokenValue = token.token;

        // console.log(tokenValue);

        // // Store token in localStorage
        // localStorage.setItem('token', token);


        this.toastr.success('Login Successfull');
        this.router.navigateByUrl('/dashboard');
      }).catch(err => {
        console.log(err);
        this.toastr.error('Login Failed');
      });
    } else {
      this.toastr.error('Please check your email and password');
      this.loginForm.markAllAsTouched();
    }

  }


  // subscribe( res => {
  //   console.log(res);
  //   this.toastr.success('Login Successfull');
  // }, err => {}, () => console.log('complete'));
}
