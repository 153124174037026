import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { auth } from 'firebase/app';
import { Observable } from 'rxjs';
// import { User } from 'firebase/auth';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  user$: Observable<firebase.User | null>;

  constructor(public afAuth: AngularFireAuth) {
    this.user$ = this.afAuth.authState;

  }

  async getIdToken(): Promise<string | null> {
    try {
      const user = await this.afAuth.currentUser;
      if (user) {
        const idToken = await user.getIdToken();
        return idToken;
      } else {
        console.error('User not authenticated.');
        return null;
      }
    } catch (error) {
      console.error('Error getting ID token:', error);
      return null;
    }
  }

  loginWithEmail(email: string, password: string): Promise<auth.UserCredential> {
    return this.afAuth.signInWithEmailAndPassword(email, password);
  }

  // Logout
  logout(): Promise<void> {
    return this.afAuth.signOut();
  }
}
