export const environment = {
  production: true,
  // firebaseFunctionsUrl: 'http://127.0.0.1:5001/youth-festive/asia-south1/',
  imageUploadUrl: 'https://api.bytescale.com/v2/accounts/kW15brF/uploads/form_data',
  firebaseFunctionsUrl: 'https://asia-south1-youth-festive.cloudfunctions.net/',
  firebaseConfig: {
    apiKey: "AIzaSyCX314rPA6kpoYOZIcvkZ56jsJAkrPevV8",
    authDomain: "youth-festive.firebaseapp.com",
    projectId: "youth-festive",
    storageBucket: "youth-festive.appspot.com",
    messagingSenderId: "18062347507",
    appId: "1:18062347507:web:79809397623a8cc29bbb86",
    measurementId: "G-234MKLGTGL"
  }
};
