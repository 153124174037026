import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import 'firebase/firestore';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from './login/login.component';

import { environment } from '../environments/environment';

import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule, BUCKET, MAX_OPERATION_RETRY_TIME } from '@angular/fire/storage';
import { Toast, ToastrModule } from 'ngx-toastr';
import { HttpClientModule } from '@angular/common/http';
import { SharedModule } from './components/shared.module';
import { UnderscoreToSpacePipePipe } from './underscore-to-space-pipe.pipe';
import { ExcerptPipe } from './excerpt.pipe';
import * as Sentry from "@sentry/angular-ivy";
import { Router } from "@angular/router";

// firebase.initializeApp(firebaseConfig);
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    // ExcerptPipe,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireDatabaseModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    ReactiveFormsModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    SharedModule,
    AppRoutingModule
  ],
  providers: [{
    provide: ErrorHandler,
    useValue: Sentry.createErrorHandler({
      showDialog: false,
    }),
  }, {
    provide: Sentry.TraceService,
    deps: [Router],
  },
  {
    provide: APP_INITIALIZER,
    useFactory: () => () => {},
    deps: [Sentry.TraceService],
    multi: true,
  },
  { provide: BUCKET, useValue: environment.firebaseConfig.storageBucket }, // Replace with your Firebase Storage bucket name
  {
    provide: MAX_OPERATION_RETRY_TIME,
    useValue: 600000, // Set the maximum retry time in milliseconds (e.g., 60 seconds)
  },
],
  bootstrap: [AppComponent]
})
export class AppModule { }
