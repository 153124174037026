<div class="container margin-top-15-p">
  <div class="row justify-content-center">
    <div class="col-md-6">
      <div class="card">
        <div class="card-header">
          <h3>Login</h3>
        </div>
        <div class="card-body">
          <form [formGroup]="loginForm">
            <div class="form-group">
              <label for="username">Email</label>
              <input type="text" formControlName="email" class="form-control" id="username"
                placeholder="Enter your email">
              <small class="text-danger"
                *ngIf="loginForm.get('email')?.hasError('required') && (loginForm.get('email')?.touched || loginForm.get('email')?.dirty)">
                Email is required.
              </small>
              <small class="text-danger"
              *ngIf="loginForm.get('email')?.hasError('email') && (loginForm.get('email')?.touched || loginForm.get('email')?.dirty)">
              Not a valid email, please check @ and .com (')
            </small>
            </div>
            <div class="form-group">
              <label for="password">Password</label>
              <input type="password" formControlName="password" class="form-control" id="password"
                placeholder="Enter your password">
            </div>
            <small class="text-danger"
            *ngIf="loginForm.get('password')?.hasError('required') && (loginForm.get('password')?.touched || loginForm.get('password')?.dirty)">
            Password is required.
          </small>
            <button type="submit" class="btn btn-primary" (click)="login()">Login</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
